import React from 'react';
import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';

import ContactModal from './ContactModal';
import Header from './Header';
import Button from './Button';

import '../styles/Claims.css';
import '../styles/Reviews.css';

const animateScroll = Scroll.animateScroll;


class Reviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactActive: false
    };
  }

  openContact = () => {
    animateScroll.scrollToTop();
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <div id="claims-container">
        <Helmet>
          <title>Reviews</title>
          <meta
            name="description"
            content="We have simplified the process of selling a bankruptcy claim. Prior to the global financial crisis of 2008, there was a greater disparity in terms for selling claims." />
        </Helmet>

    
        <Header isHome={false} />

        <div id="claims">
          <div className="intro">
            <h1 className="title">Reviews</h1>
          </div>

          <div className="reviews-wrapper">
            <iframe src="https://widget.tagembed.com/52858?view"></iframe>
          </div>

         
        </div>
      </div>
    );
  }
}

export default Reviews;
