import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

import Header from './Header';
import Button from './Button';
import ContactModal from './ContactModal';

import '../styles/Home.css';

const animateScroll = Scroll.animateScroll;

const intros = [
  {
    title: 'Claims',
    link: 'claims',
    icon: './assets/icons/claims.png',
    detail:
      'Celsius is a leading buyer of claims in bankruptcy and class action cases. We focus on a rapid and efficient closing process.'
  },
  {
    title: 'Vendor Put Options',
    link: 'put-options',
    icon: './assets/icons/energy.png',
    detail:
      'Celsius actively arranges and sells Put Options to Vendors seeking to protect risky receivables. Put Options offer the advantage of non-cancelable protection for an agreed term.'
  },
  {
    title: 'Receivables Finance',
    link: 'receivables',
    icon: './assets/icons/venture.png',
    detail:
      'Celsius offers immediate\ncash for vendor receivables. Onboarding only takes 7-10 days. This is the fastest way to obtain receivables financing.'
  }
];

const philosophyDetails = [
  'Markets move fast. We prize agility and creativity.',
  'Our office is optimized for collaboration. We share ideas on strategy, technology and marketing.',
  'We use technology to leverage the talents of our people and help us to better work with our clients.'
];

const openings = [
  {
    title: 'Marketing Intern',
    description:
      'We are seeking an enthusiastic, creative, and dependable marketing intern to join our growing marketing team. In this position, you will collaborate with our marketing team in each stage of the marketing process. Your work will include developing, growing, and expanding our marketing channels through social media outreach, phone calls, and strategic planning.'
  },
  {
    title: 'Data Analysis Intern',
    description:
      'We’re seeking a detail-oriented, methodical, and collaborative data analyst to add to our team of driven professionals. As a data analysis intern, you will play a key role in our organization. Your primary goal will be to leverage data and communicate your findings to help us make smarter, data-driven business decisions.'
  }
];

const detailCard = (info, key) => {
  return (
    <div className="card" key={key}>
      <img src={info.icon} />
      <div>
        <h4>{info.title}</h4>
        <p className="detail">
        {info.detail.split('\n').map((item, key) => {
          return <span key={key}>{item}<br/></span>
        })}</p>
      </div>

      <Link
        to={`/${info.link}`}
        className="link"
        onClick={animateScroll.scrollToTop}>

        <p className="read-more">READ MORE</p>
      </Link>
    </div>
  );
};

const philosophyDetailItem = (item, key) => {
  return (
    <div className="detail" key={key}>
      <p>{item}</p>
    </div>
  );
};

const openingItem = (position, key) => {
  return (
    <div className="card" key={key}>
      <h4>{position.title}</h4>
      <p className="description">{position.description}</p>
      <Button
        title="Apply"
        onButtonClick={() =>
          (window.location.href = `mailto:all@cherokeeacq.com`)
        } />

    </div>
  );
};

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactActive: false
    };
  }

  openContact = () => {
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <React.Fragment>
        <Header isHome={true} />
        <div className="scene-video-container">
          <video autoPlay loop muted className="scene-video">
            <source src="/assets/media/hero_video.mp4" type="video/mp4" />
            <source src="/assets/media/hero_video.webm" type="video/webm" />
            {/* <source src='/assets/media/hero_video.ogv' type='video/ogg' /> */}
          </video>
        </div>

        {/* <div className='scene-image'/> */}
        <div className="scene-gradient" />
        <div className="scene-white-wedge" />
        <div id="home">
          <div className="intro">
            <div className="title-container">
              <h1 className="title">Celsius Claims</h1>
              <h3 className="subtitle">
                <span className="bold">Cherokee Aquisition</span> is actively buying bankruptcy claims. We focus relentlessly on providing great service to our clients.  Please tap the button below to contact us. We will respond quickly!
              </h3>
            </div>

            <Button title="Get in touch" onButtonClick={this.openContact} />
          </div>

          <div className="philosophy">
            <p className="seo-text">Do you have a Celsius Network account that is caught up in the Celsius bankruptcy ? We can help you to get cash quickly if you are interested in selling your Celsius Claim. Just tap the “Get in touch” button above to reach us.</p>
            <h1 className="title">Our Philosophy</h1>
            <img src="./assets/icons/philosophy.png" />
            <div className="details-container">
              {philosophyDetails.map((detail, i) =>
                philosophyDetailItem(detail, i)
              )}
            </div>
          </div>
        </div>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />

      </React.Fragment>
    );
  }
}

export default Home;
