import React from 'react'
import {Button} from 'react-bootstrap'
import * as Scroll from 'react-scroll'
import { Link } from 'react-router-dom';

import '../styles/Footer.css';

const animateScroll = Scroll.animateScroll

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.checkLocation = this.checkLocation.bind(this)
  }

  checkLocation(position) {
    this.forceUpdate()

    setTimeout(function() {
      var destination = document.querySelector('#' + position)
      const elementPosition = destination.offsetTop - 30
      animateScroll.scrollTo(elementPosition, {
        smooth: true,
        duration: 500
      })
    }, 200)
  }

  render() {
    return (
      <div id="footer" className="section">
        <footer className="page-footer">
          <div className="container text-md-left">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="brand-info">
                  <Link to="/" className="link" onClick={animateScroll.scrollToTop}>
                    <h4>Cherokee Acquisition</h4>
                  </Link>

                  <a
                    className="address"
                    href="https://goo.gl/maps/iXMt5k1c7xLJfNA67"
                    target="_blank"
                    rel="noopener noreferrer">

                    <div>1384 Broadway, Suite 906</div>
                    <div>New York, NY 10018</div>
                  </a>

                  <p><a href="tel:2122594300">(212) 259-4300</a><br/><a href="mailto:all@cherokeeacq.com">all@cherokeeacq.com</a></p>
                </div>
                
              </div>
        
    
              <div className="col-md-6 mb-md-0 mb-3 icons">
                <a
                  href="https://www.linkedin.com/company/cherokee-acquisition/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/linkedin.png"
                    className="img-fluid icon"
                    alt="linkedin"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCrL-kqeiZGz7_WH9mAXKTrg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/youtube.png"
                    className="img-fluid icon icon-right"
                    alt="youtube"
                  />
                </a>
              </div>
            </div>
          </div>
         
          <div className="footer-copyright text-center py-3">
            <a href="http://cherokeeacq.com/" target="_blank">
              © 2023 Cherokee Hybrid Markets, Inc.
            </a>
          </div>
          <div className="footer-meeplabs text-center">
            <a href="https://ravennainteractive.com/" target="_blank">
              Site Developed by Ravenna
            </a>
          </div>
        </footer>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

export default Footer;
