import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';

import '../styles/Resources.css';


// Testing for deploy
// testing 2

const years = [
  { 
    year: 2022, 
    resources: [
      {
        id: 20,
        name: 'Bloomberg: Wall Street Pros Offer Crypto Holders a Backdoor Bankruptcy Exit',
        month: '8',
        day: '9',
        url:
          'https://www.bloomberg.com/news/articles/2022-08-09/wall-street-pros-offer-crypto-holders-a-backdoor-bankruptcy-exit#xj4y7vzkg'
      },
       {
        id: 6,
        name: 'Medium: Crypto Contagion',
        month: '7',
        day: '17',
        url:
          'https://medium.com/@jellyroll505/crypto-contagion-95c2c6e57902'
      },
      {
        id: 6,
        name: 'Jones & Associates: Bankruptcy Claims Markets',
        month: '3',
        day: '17',
        url:
          'https://www.youtube.com/watch?v=GRbvpegLgQg'
      },
    ]
  },
  { 
    year: 2021, 
    resources: [
      {
        id: 6,
        name: 'Bloomberg: Investors Vie for Windfall From Rejected Covid Insurance Claims',
        month: '2',
        day: '11',
        url:
          'https://www.bloomberg.com/news/articles/2021-02-12/investors-vie-for-windfall-from-rejected-covid-insurance-claims#xj4y7vzkg'
      },
    ]
  },
  { 
    year: 2020, 
    resources: [
      {
        id: 6,
        name: 'Wall Street Journal: Vendor Put Options',
        month: '10',
        day: '27',
        url:
          'https://www.wsj.com/articles/your-holiday-presents-arrival-could-depend-on-these-fund-managers-11603790891'
      },
      {
        id: 6,
        name: 'Retail Dive: Retail suppliers are still under strain – and it could hurt everyone',
        month: '8',
        day: '10',
        url:
          'https://www.retaildive.com/news/retail-suppliers-are-still-under-strain-and-it-could-hurt-everyone/583072/'
      },
      {
        id: 6,
        name: 'Retail Dive: Stage Stores cuts staff, closes dozens of stores',
        month: '2',
        day: '26',
        url:
          'https://www.retaildive.com/news/stage-stores-lays-off-staff-closes-more-stores/573054/'
      },
      {
        id: 7,
        name: 'Medium: Claims Market',
        month: '4',
        day: '17',
        url:
          'https://medium.com/@jellyroll505/bankruptcy-claims-a4805bad307d'
      },
    ]
  },
  { 
    year: 2019, 
    resources: [
      {
        id: 1,
        name: 'Wall Street Journal: PG&E Plan to Pay Insurance Claims in Cash Hurts Bankruptcy Talks',
        month: '11',
        day: '8',
        url: 'https://www.wsj.com/articles/pg-e-plan-to-pay-insurance-claims-in-cash-hurts-bankruptcy-talks-11573222528'
      },
      {
        id: 2,
        name: 'Claims Journal: Hedge Funds Buy Almost $500 Million in Claims From PG&E Vendors',
        month: '8',
        day: '14',
        url: 'https://www.claimsjournal.com/news/west/2019/08/14/292528.htm'
      },
      {
        id: 3,
        name: 'New York Post: JC Penney vendors receive letters offering credit risk protection',
        month: '5',
        day: '31',
        url: 'https://nypost.com/2019/05/31/jc-penney-vendors-receive-letters-offering-credit-risk-protection/'
      }
    ]
  },
  { 
    year: 2018, 
    resources: [
      {
        id: 6,
        name: 'Bloomberg: Sears Suppliers Sell Trade Claims Amid Cash Shortage Concern',
        month: '12',
        day: '11',
        url:
          'https://www.bloomberg.com/news/articles/2018-12-11/sears-suppliers-offload-trade-claims-amid-cash-shortage-concerns'
      },
      {
        id: 7,
        name: 'NYU Entrepreneurship: Interview with Vladimir Jelisavcic',
        month: '3',
        day: '30',
        url:
          'http://entrepreneur.nyu.edu/blog/2018/03/30/interview-vladimir-jelisavcic-seasoned-financial-entrepreneur/'
      },
    ]
  }
];

const yearCard = year => (
  <div className="member-container" key={year.year}>
    <h3>{year.year}</h3>
    {year.resources.map(resource => resourceCard(resource, year.year))}
  </div>
);

const resourceCard = (resource, year) => (
  <div key={resource.id}>
    <a href={resource.url} className="link" target="_blank">
        {resource.name}
      </a>{' '}
      ({resource.month}.{resource.day}.{year})
  </div>
);

const Resources = () => {
  return (
    <div id="resources-container">
      <Helmet>
        <title>Resources</title>
        <meta
          name="description"
          content="Check out some of the resources behind Cherokee Acquisition."
        />
      </Helmet>
      <Header isHome={false} />
      <div id="resources">
        <div className="intro">
          <h1 className="title">Resources</h1>
          <img className="team-icon" src="./assets/icons/internships.png" />
          <div className="info-container">
            {years.map(year => yearCard(year))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
