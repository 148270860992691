import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Button from './Button';
import Carousel from './Carousel';
import ContactModal from './ContactModal';

import '../styles/PutOptions.css';

class PutOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactActive: false
    };
  }

  openContact = () => {
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    const carouselItems = [
      './assets/images/claims_put_market_1.png',
      './assets/images/claims_put_market_2.png',
      './assets/images/claims_put_market_3.png'
    ];

    return (
      <div id="energy-container">
        <Header isHome={false} />
        <Helmet>
          <title>Vendor Put Options</title>
          <meta
            name="description"
            content="Cherokee's team has been investing in the energy sector since 2000." />

        </Helmet>

        <div id="energy">
          <div className="intro">
            <h1 className="title">Vendor Put Options</h1>
            <Button 
              title="Visit Put Market"
              onButtonClick={() =>
                window.open('https://www.claimsputmarket.com', '_blank')
              } />
            {/* <img className="energy-icon" src="./assets/icons/energy.png" /> */}
            <div className="detail-container">
              <div className="img-container second">
                <img src="./assets/images/energy_intro_1.png" />
              </div>

              <div className="description-container">
                <p>
                  Claims Put Market matches vendors seeking credit protection on 
                  accounts receivables with investors offering protection. The 
                  advantage to a vendor of Claims Put Market is that their requested 
                  put option is shown to multiple investors that a vendor might 
                  not otherwise find. This “broadcasting” of a vendor’s requested 
                  put option results in a more transparent and fair market.
                </p>
              </div>
            </div>

            <div className="detail-container">
              <div className="description-container second">
                <p>
                  The Cherokee team has executed numerous put option transactions 
                  for Vendors on multiple Customers including: Bed Bath &amp; Beyond, Belk,
                  J.C. Penney, Macy's, Michaels, and Rite Aid.
                </p>
              </div>

              <div className="img-container first">
                <img src="./assets/images/energy_intro_2.png" />
              </div>
            </div>
          </div>

          <div className="mineral-market">
            <h1 className="title">Claims Put Market</h1>
            <p>
              Claims Put Market features transparent reference prices. 
              Most vendor put options are priced with reference to Credit 
              Default Swap (CDS) prices. We display CDS prices of customers 
              that have actively traded vendor put options. Viewing displayed 
              CDS prices gives vendors a sense for the potential<br />price of a 
              put option.
            </p>
            
            <Carousel items={carouselItems} />
            <p>
              Cherokee is active in structuring vendor put options that protect receivables from a<br />customer’s potential bankruptcy.
            </p>

            <Button
              title="Visit Put Market"
              onButtonClick={() =>
                window.open('https://www.claimsputmarket.com', '_blank')
              } />

          </div>
        </div>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />

      </div>
    );
  }
}

export default PutOptions;
